import '../styles/home.sass';
import {Icon} from "../components/icon";
import {Document} from "../icons/document-outline";
import {Button} from "../components/button";
import {HelpCircleOutline2} from "../icons/help-circle-outline (2)";
import {Search} from "../icons/search";
import {Arrow} from "../icons/blackIcon";
import {Vector} from "../icons/Vector";
import {ArchiveOutline} from "../icons/archive-outline";
import {BifileEarmarkArrowDown} from "../icons/bi_file-earmark-arrow-down";

export const Home = () => {
    return (
        <div className={'home'}>
            <section className={'headerHome'}>
                <ul className={'topMenu'}>
                    <li className={'active'}>Lorem Ipsum Lore</li>
                    <li className={'divider'}>|</li>
                    <li>Lorem Ipsum Lorem Ipsum</li>
                    <li className={'divider'}>|</li>
                    <li>Lorem Ipsum Lorem IpsumLorem Ipsum Lorem Ipsum Lorem</li>
                </ul>
               <Button
                   icon={<Icon icon={<Document />} transparentBg smallSize />}
                   text={'Ajouter Un Document'}
               />
            </section>

            <section className={'tableMenuHome'}>
                <span className={'filterSelected'}>Documents courants </span>
                <span className={'filterDeleted'}>Documents archivés </span>
                <div className={'searchBox'}>
                    <input type="text" placeholder={'Recherche'} /><Search />
                </div>
            </section>

            <section className={'tableHome'}>
                <div className={"table"}>
                    <div className={"th first faded"}><Vector /> FILTER PAR</div>
                    <div className={"th faded"}>Nom du document</div>
                    <div className={"th faded"}>Numero</div>
                    <div className={"th"}>Type <Arrow /></div>
                    <div className={"th"}>Du - Au <Arrow /></div>
                    <div className={"th faded"}>Ajouté par</div>
                    <div className={"th last"}></div>

                    <div className={"td header first"}>Documents</div>
                    <div className={"td header"}>Numero</div>
                    <div className={"td header"}>Type</div>
                    <div className={"td header"}>Date d’ajout </div>
                    <div className={"td header"}>Ajouté par </div>
                    <div className={"td header"}>Fichier</div>
                    <div className={"td header last"}></div>

                    <div className={"td first"}><input type="checkbox"/>Lorem IpsumLorem Ipsum</div>
                    <div className={"td"}>493973</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}>14/04/2021</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}><BifileEarmarkArrowDown /></div>
                    <div className={"td last"}><ArchiveOutline /></div>

                    <div className={"td first"}><input type="checkbox"/>Lorem IpsumLorem Ipsum</div>
                    <div className={"td"}>493973</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}>14/04/2021</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}><BifileEarmarkArrowDown /></div>
                    <div className={"td last"}><ArchiveOutline /></div>

                    <div className={"td first"}><input type="checkbox"/>Lorem Ipsum</div>
                    <div className={"td"}>493973</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}>14/04/2021</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}><BifileEarmarkArrowDown /></div>
                    <div className={"td last"}><ArchiveOutline /></div>

                    <div className={"td first"}><input type="checkbox"/>Lorem Ipsum</div>
                    <div className={"td"}>493973</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}>14/04/2021</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}><BifileEarmarkArrowDown /></div>
                    <div className={"td last"}><ArchiveOutline /></div>

                    <div className={"td first"}><input type="checkbox"/>Lorem IpsumLorem Ipsum</div>
                    <div className={"td"}>493973</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}>14/04/2021</div>
                    <div className={"td"}>Lorem Ipsum</div>
                    <div className={"td"}><BifileEarmarkArrowDown /></div>
                    <div className={"td last"}><ArchiveOutline /></div>
                </div>
            </section>

            <section className={'adHome'}>
                <div>
                    <h2>Besoin d'aide ?</h2>
                    <p>Consultez notre aide en ligne ou contactez-nous</p>
                </div>
                <Button
                    icon={<Icon icon={<HelpCircleOutline2 />} transparentBg smallSize />}
                    text={'Consulter Le Centre D’aide'}
                />
            </section>

            <section className={'footerLinksHome'}>
                <div className={'mainLinks'}>
                    <div className={'firstColumn'}>
                        <h3>À propos de nom</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Nous contacter</li>
                            <li>Presse</li>
                            <li>Besoin d'aide ?</li>
                            <li>Notifications frauduleuses (tentatives de phishing)</li>
                        </ul>
                    </div>

                    <div className={'secondColumn'}>
                        <h3>Trouvez votre</h3>
                        <ul className={'wrapMenu'}>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum et Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li><b>Lorem Ipsum</b></li>
                            <li><b>Lorem Ipsum Lorem</b></li>

                            <li>Lorem Ipsum Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>


                    <div className={'thirdColumn'}>
                        <h3>Recherches fréquentes</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum Lorem Ipsum et Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum Lorem Ipsum et Lorem</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem IpsumLorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>

                </div>

                <div className={'alphabetLinks'}>
                    <h3 className={'linkHeaderSmall'}>Recherche de Lorem Ipsum</h3>
                    <span className={'simpleHeaderText'}>Lorem Ipsum Lorem Ipsum</span>
                    <span className={'letters'}>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z</span>
                </div>
            </section>
        </div>
    );
}