import '../styles/footer.sass';
import {Icon} from "./icon";
import {LogoFacebook} from "../icons/logo-facebook";
import {LogoLinkedin} from "../icons/logo-linkedin";
import {Insta} from "../icons/insta";
import {Twitter} from "../icons/twitter";

export const Footer = () => {
    return (
        <footer>
            <div>
                <div className={'footerLogo'}>Logo</div>
                <div className={'copyright'}>Copyright © 2021, tous droits réservés.</div>
            </div>
            <div className={'social'}>
                <Icon icon={<LogoFacebook />} whiteBg />
                <Icon icon={<LogoLinkedin />} whiteBg />
                <Icon icon={<Insta />} whiteBg />
                <Icon icon={<Twitter />} whiteBg />
            </div>
        </footer>
    );
}
