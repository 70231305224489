export const Twitter = () =>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31 6.84376C29.8747 7.33273 28.684 7.65487 27.4656 7.80001C28.746 7.05025 29.709 5.85911 30.1737 4.45001C28.9632 5.15788 27.641 5.65455 26.2638 5.91876C25.6838 5.31097 24.9863 4.82745 24.2137 4.4976C23.4411 4.16775 22.6095 3.99846 21.7694 4.00001C18.3681 4.00001 15.6156 6.71251 15.6156 10.0563C15.6132 10.5214 15.6665 10.9851 15.7744 11.4375C13.3354 11.3232 10.9472 10.701 8.76249 9.61079C6.57777 8.52059 4.64468 6.98636 3.08687 5.10626C2.54036 6.02759 2.25133 7.07879 2.25 8.15001C2.25 10.25 3.34562 12.1063 5 13.1938C4.01983 13.1705 3.05974 12.9111 2.20125 12.4375V12.5125C2.20125 15.45 4.32625 17.8938 7.13875 18.45C6.60986 18.591 6.06486 18.6624 5.5175 18.6625C5.12911 18.6632 4.74161 18.6255 4.36062 18.55C5.1425 20.9563 7.41813 22.7063 10.1138 22.7563C7.92336 24.4443 5.23414 25.3567 2.46875 25.35C1.97789 25.3493 1.48748 25.3201 1 25.2625C3.81324 27.0589 7.0834 28.0091 10.4213 28C21.7563 28 27.9487 18.7688 27.9487 10.7625C27.9487 10.5 27.9419 10.2375 27.9294 9.98126C29.1313 9.1263 30.1711 8.06383 31 6.84376Z" fill="url(#paint0_linear_2_426)"/>
        <defs>
        <linearGradient id="paint0_linear_2_426" x1="16" y1="4" x2="16" y2="28.0001" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9E58FF"/>
        <stop offset="1" stopColor="#C989DD"/>
        </linearGradient>
        </defs>
    </svg>
