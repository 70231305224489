export const LogoFacebook = () =>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M30 16.0844C30 8.3531 23.7313 2.08435 16 2.08435C8.26875 2.08435 2 8.3531 2 16.0844C2 23.0718 7.11875 28.8637 13.8125 29.915V20.1325H10.2569V16.0844H13.8125V13C13.8125 9.49185 15.9031 7.55248 19.1006 7.55248C20.6325 7.55248 22.235 7.82623 22.235 7.82623V11.2719H20.4688C18.7306 11.2719 18.1869 12.3506 18.1869 13.4594V16.0844H22.0694L21.4494 20.1325H18.1875V29.9162C24.8813 28.8656 30 23.0737 30 16.0844Z" fill="url(#paint0_linear_2_432)"/>
        <defs>
        <linearGradient id="paint0_linear_2_432" x1="16" y1="2.08435" x2="16" y2="29.9162" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9E58FF"/>
        <stop offset="1" stopColor="#C989DD"/>
        </linearGradient>
        </defs>
    </svg>
