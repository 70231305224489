import '../styles/dashboard.sass';
import {Vector} from "../icons/Vector";
import {TimeOutline} from "../icons/time-outline";
import {MakiDoctor} from "../icons/maki_doctor-15";
import {LeftArrow} from "../icons/leftArrow";

export const Dashboard = () => {
    return (
        <div className={'dashboard'}>
            <div className={'left'}>
                <div className={'content'}>
                    <div className={'headerSection'}>
                        <h2>Mes Lorem Ipsum</h2>
                        <button><Vector /> Filter</button>
                    </div>

                    <div className="taskSection">
                        <div className="card yellow">
                            <div className="headCard">
                                <span className={'text'}>Lorem Ipsum</span>
                                <span className={'timer'}><TimeOutline /> 09:00</span>
                                <span className={'marker'}><MakiDoctor /></span>
                            </div>
                            <div className={'cardContent'}>
                                <h4>Moha</h4>
                                <span className={'subHeader'}>Lorem Ipsum</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci fames viverra suspendisse a sed viverra proin vitae. At cursus pulvinar ac est urna.</p>
                                <div className={'buttonWrapper'}>
                                    <button>Lorem</button>
                                </div>
                            </div>
                        </div>

                        <div className="card purple">
                            <div className="headCard">
                                <span className={'text'}>Lorem Ipsum</span>
                                <span className={'timer'}><TimeOutline /> 09:00</span>
                                <span className={'marker'}><MakiDoctor /></span>
                            </div>
                            <div className={'cardContent'}>
                                <h4>Moha</h4>
                                <span className={'subHeader'}>Lorem Ipsum</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci fames viverra suspendisse a sed viverra proin vitae. At cursus pulvinar ac est urna.</p>
                                <div className={'buttonWrapper'}>
                                    <button>Lorem</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'footerSection'}>
                    <span className={"redBadge"}>Lorem Ipsum Lorem</span>
                    <span className={"greyBadge"}>Lorem Ipsum Lorem</span>
                </div>

            </div>

            <div className={'right'}>
                <div className={'rightHeader'}>
                    <div className={'infoSection'}>
                        <h2>Mon agenda</h2>
                        <button>Button 2</button>
                    </div>

                    <div className={'tagSection'}>
                        <span className={'greyBadge'}>Journée</span>
                        <span className={'greyBadge active'}>Semaine</span>
                        <span className={'greyBadge'}>Mois</span>
                    </div>
                </div>

                <section className={'rightTable'}>
                    <div className={'arrowWrapper rightArrow'}><LeftArrow /></div>

                    <div className={"table"}>
                        <div className={"th first"}><div className={'arrowWrapper'}><LeftArrow /></div></div>
                        <div className={"th"}><div>Lun</div><div><b>17/04</b></div></div>
                        <div className={"th faded"}><div>Mar</div><div><b>18/04</b></div></div>
                        <div className={"th faded"}><div>Mer</div><div><b>19/04</b></div></div>
                        <div className={"th faded"}><div>Jeu</div><div><b>20/04</b></div></div>
                        <div className={"th faded"}><div>Ven</div><div><b>21/04</b></div></div>
                        <div className={"th faded"}><div>Sam</div><div><b>22/04</b></div></div>
                        <div className={"th faded last"}><div>Dim</div><div><b>23/04</b></div></div>

                        <div className={"td first"}><span className={'schedule'}>08:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>09:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>10:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>11:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>12:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}>
                            <div className={'booked'}>
                                <span className={'title'}>Lorem ipsum</span>
                                <span className={'time'}>09:00</span>
                                <span className={'badgeRight'}>Lorem</span>
                            </div>
                        </div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>13:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>14:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>15:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>16:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>17:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>18:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>19:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>20:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>21:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>

                        <div className={"td first"}><span className={'schedule'}>22:00</span></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td"}></div>
                        <div className={"td last"}></div>
                    </div>
                </section>
            </div>
        </div>
    );
}