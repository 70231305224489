import '../styles/icon.sass'

export const Icon = ({ icon, isError, whiteBg, transparentBg, smallSize }) => {
    return <div className={
        "roundCircle"
        + (whiteBg? " whiteBg " : "")
        + (transparentBg? " transparentBg " : "")
        + (smallSize? " smallSize " : "")
    }>
        {isError && <div className="upper"></div>}
        {icon}
    </div>
}