export const Insta = () =>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0001 11.8344C13.7064 11.8344 11.8345 13.7062 11.8345 16C11.8345 18.2937 13.7064 20.1656 16.0001 20.1656C18.2939 20.1656 20.1657 18.2937 20.1657 16C20.1657 13.7062 18.2939 11.8344 16.0001 11.8344ZM28.4939 16C28.4939 14.275 28.5095 12.5656 28.4126 10.8437C28.3157 8.84373 27.8595 7.06873 26.397 5.60623C24.9314 4.1406 23.1595 3.68748 21.1595 3.5906C19.4345 3.49373 17.7251 3.50935 16.0032 3.50935C14.2782 3.50935 12.5689 3.49373 10.847 3.5906C8.84698 3.68748 7.07198 4.14373 5.60948 5.60623C4.14385 7.07185 3.69073 8.84373 3.59385 10.8437C3.49698 12.5687 3.5126 14.2781 3.5126 16C3.5126 17.7219 3.49698 19.4344 3.59385 21.1562C3.69073 23.1562 4.14698 24.9312 5.60948 26.3937C7.0751 27.8594 8.84698 28.3125 10.847 28.4094C12.572 28.5062 14.2814 28.4906 16.0032 28.4906C17.7282 28.4906 19.4376 28.5062 21.1595 28.4094C23.1595 28.3125 24.9345 27.8562 26.397 26.3937C27.8626 24.9281 28.3157 23.1562 28.4126 21.1562C28.5126 19.4344 28.4939 17.725 28.4939 16ZM16.0001 22.4094C12.4532 22.4094 9.59073 19.5469 9.59073 16C9.59073 12.4531 12.4532 9.5906 16.0001 9.5906C19.547 9.5906 22.4095 12.4531 22.4095 16C22.4095 19.5469 19.547 22.4094 16.0001 22.4094ZM22.672 10.825C21.8439 10.825 21.1751 10.1562 21.1751 9.3281C21.1751 8.49998 21.8439 7.83123 22.672 7.83123C23.5001 7.83123 24.1689 8.49998 24.1689 9.3281C24.1691 9.52475 24.1306 9.7195 24.0554 9.90122C23.9803 10.0829 23.87 10.2481 23.731 10.3871C23.5919 10.5262 23.4268 10.6364 23.2451 10.7115C23.0634 10.7867 22.8686 10.8252 22.672 10.825Z" fill="url(#paint0_linear_2_428)"/>
        <defs>
        <linearGradient id="paint0_linear_2_428" x1="16.0035" y1="3.50842" x2="16.0035" y2="28.4915" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9E58FF"/>
        <stop offset="1" stopColor="#C989DD"/>
        </linearGradient>
        </defs>
    </svg>
