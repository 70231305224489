export const LogoLinkedin = () =>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.7606 2H4.3925C3.11562 2 2 2.91875 2 4.18062V27.6006C2 28.8694 3.11562 30 4.3925 30H27.7537C29.0375 30 30 28.8619 30 27.6006V4.18062C30.0075 2.91875 29.0375 2 27.7606 2ZM10.6794 25.3394H6.66813V12.8675H10.6794V25.3394ZM8.8125 10.9712H8.78375C7.5 10.9712 6.66875 10.0156 6.66875 8.81938C6.66875 7.60125 7.52188 6.66813 8.83438 6.66813C10.1469 6.66813 10.95 7.59438 10.9788 8.81938C10.9781 10.0156 10.1469 10.9712 8.8125 10.9712ZM25.3394 25.3394H21.3281V18.52C21.3281 16.8863 20.7444 15.77 19.2931 15.77C18.1844 15.77 17.5281 16.52 17.2362 17.2506C17.1269 17.5131 17.0975 17.8706 17.0975 18.2356V25.3394H13.0863V12.8675H17.0975V14.6031C17.6812 13.7719 18.5931 12.5756 20.715 12.5756C23.3481 12.5756 25.34 14.3113 25.34 18.0531L25.3394 25.3394Z" fill="url(#paint0_linear_2_430)"/>
        <defs>
        <linearGradient id="paint0_linear_2_430" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9E58FF"/>
        <stop offset="1" stopColor="#C989DD"/>
        </linearGradient>
        </defs>
    </svg>
