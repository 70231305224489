import { Link } from "react-router-dom";
import '../styles/header.sass';
import { Icon } from "./icon";
import { DocumentsOutline } from "../icons/documents-outline";
import {CalendarOutline} from "../icons/calendar-outline";
import {NotificationOutline} from "../icons/notifications-outline";
import {HelpCircleOutline} from "../icons/help-circle-outline";
import {PersonCircleOutline} from "../icons/person-circle-outline";
import {Vector56} from "../icons/Vector 56";

export const Header = () => {
    return (
        <>
            <header>
                <ul>
                    <li className={'logoHeader'}>Logo</li>
                    <li className={'inline-top'}>
                        <div className={'inline'}>
                            <Icon icon={<CalendarOutline />} whiteBg />
                            <Icon icon={<DocumentsOutline />} isError />
                            <Icon icon={<NotificationOutline />} isError whiteBg />
                            <Icon icon={<HelpCircleOutline />} whiteBg />
                        </div>
                        <div className={'inline'}>
                            <Icon icon={<PersonCircleOutline />} transparentBg smallSize />
                            <span className={'dropdown'}>Full name Lorem </span>
                            <Icon icon={<Vector56 />} transparentBg smallSize />
                        </div>
                    </li>
                </ul>
            </header>
            <ul className={'menu'}>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/dashboard">Dashboard</Link>
                </li>
            </ul>
        </>
    );
}
