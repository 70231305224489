import { Routes, Route } from "react-router-dom";
import { Layout } from "./layouts";
import { Home } from "./pages/home";
import { Dashboard} from "./pages/dashboard";
import { NoMatch } from "./pages/noMatch";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    </div>
  );
}

export default App;
