export const CalendarOutline = () =>
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6875 3.59375H4.3125C3.12164 3.59375 2.15625 4.55914 2.15625 5.75V18.6875C2.15625 19.8784 3.12164 20.8438 4.3125 20.8438H18.6875C19.8784 20.8438 20.8438 19.8784 20.8438 18.6875V5.75C20.8438 4.55914 19.8784 3.59375 18.6875 3.59375Z" stroke="#C989DD" strokeWidth="1.53788" clipRule="round"/>
        <path d="M13.2969 11.5C13.8923 11.5 14.375 11.0173 14.375 10.4219C14.375 9.82644 13.8923 9.34375 13.2969 9.34375C12.7014 9.34375 12.2188 9.82644 12.2188 10.4219C12.2188 11.0173 12.7014 11.5 13.2969 11.5Z" fill="#C989DD"/>
        <path d="M16.8906 11.5C17.4861 11.5 17.9688 11.0173 17.9688 10.4219C17.9688 9.82644 17.4861 9.34375 16.8906 9.34375C16.2952 9.34375 15.8125 9.82644 15.8125 10.4219C15.8125 11.0173 16.2952 11.5 16.8906 11.5Z" fill="#C989DD"/>
        <path d="M13.2969 15.0938C13.8923 15.0938 14.375 14.6111 14.375 14.0156C14.375 13.4202 13.8923 12.9375 13.2969 12.9375C12.7014 12.9375 12.2188 13.4202 12.2188 14.0156C12.2188 14.6111 12.7014 15.0938 13.2969 15.0938Z" fill="#C989DD"/>
        <path d="M16.8906 15.0938C17.4861 15.0938 17.9688 14.6111 17.9688 14.0156C17.9688 13.4202 17.4861 12.9375 16.8906 12.9375C16.2952 12.9375 15.8125 13.4202 15.8125 14.0156C15.8125 14.6111 16.2952 15.0938 16.8906 15.0938Z" fill="#C989DD"/>
        <path d="M6.10938 15.0938C6.70481 15.0938 7.1875 14.6111 7.1875 14.0156C7.1875 13.4202 6.70481 12.9375 6.10938 12.9375C5.51394 12.9375 5.03125 13.4202 5.03125 14.0156C5.03125 14.6111 5.51394 15.0938 6.10938 15.0938Z" fill="#C989DD"/>
        <path d="M9.70312 15.0938C10.2986 15.0938 10.7812 14.6111 10.7812 14.0156C10.7812 13.4202 10.2986 12.9375 9.70312 12.9375C9.10769 12.9375 8.625 13.4202 8.625 14.0156C8.625 14.6111 9.10769 15.0938 9.70312 15.0938Z" fill="#C989DD"/>
        <path d="M6.10938 18.6875C6.70481 18.6875 7.1875 18.2048 7.1875 17.6094C7.1875 17.0139 6.70481 16.5312 6.10938 16.5312C5.51394 16.5312 5.03125 17.0139 5.03125 17.6094C5.03125 18.2048 5.51394 18.6875 6.10938 18.6875Z" fill="#C989DD"/>
        <path d="M9.70312 18.6875C10.2986 18.6875 10.7812 18.2048 10.7812 17.6094C10.7812 17.0139 10.2986 16.5312 9.70312 16.5312C9.10769 16.5312 8.625 17.0139 8.625 17.6094C8.625 18.2048 9.10769 18.6875 9.70312 18.6875Z" fill="#C989DD"/>
        <path d="M13.2969 18.6875C13.8923 18.6875 14.375 18.2048 14.375 17.6094C14.375 17.0139 13.8923 16.5312 13.2969 16.5312C12.7014 16.5312 12.2188 17.0139 12.2188 17.6094C12.2188 18.2048 12.7014 18.6875 13.2969 18.6875Z" fill="#C989DD"/>
        <path d="M17.25 2.15625V3.59375M5.75 2.15625V3.59375V2.15625Z" stroke="#C989DD" strokeWidth="1.53788" strokeLinecap="round" clipRule="round"/>
        <path d="M20.8438 7.1875H2.15625" stroke="#C989DD" strokeWidth="1.53788" clipRule="round"/>
    </svg>
